@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "assets/styles/bixby.scss";

body {
    margin: 0;
    padding: 0;
}

a {
    &:hover {
        text-decoration: none;
    }
}

.bixby-fetch-question-input {
    display: inline-block;
    margin-right: 10px;
}

.phoenix-menu-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-radius: 2px;
    color: inherit;
    line-height: 20px;
    padding: 5px 7px;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
}

.ci-report-view {
    display: flex;
    @media only screen and (max-width: 1200px) {
        flex-direction: column;
    }
    .ci-report-view-text {
        @media only screen and (max-width: 1200px) {
            width: 100%;
            height: 600px;
            margin-bottom: 25px;
        }
        width: 800px; 
        height: 800px; 
        margin-right: 20px;
        margin-bottom: 0;
    }
    .ci-report-view-image {
        @media only screen and (max-width: 1200px) {
            height: auto;
            width: 100%;
        }
        height: auto;
        width: 100%;
    }
}

#phoenix-container {
    display: flex;
    min-height: 100vh;
    background-color: #293742;
    #phoenix-signin {
        min-width: 350px;
        text-align: center;
        margin: auto;
        p {
            margin-bottom: 30px;
        }
        .signin-button {
            width: 100%;
        }
    }
    #phoenix-sidebar {
        width: 250px;
        border-radius: 0;
        .phoenix-logo {
            display: flex;
            box-sizing: border-box;
            padding: 10px;
            justify-content: center;
        }
    }
    #phoenix-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        #phoenix-dashboard {
            flex: 1;
            box-sizing: border-box;
            padding: 20px;
        }
    }
}

.causal-header {
    background-color: #F8F8F8;
    border-bottom: 1px solid #E7E7E7;
}
.causal-inferencing-reports-table {
    display: flex;
    flex-direction: column;
    a {
        align-self: flex-end;
    }
}
.causal-body {
    flex: 1;
    .causal-report-name-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cancel-generate-container {
            .cancel-button {
                margin-right: 20px;
            }
        }
    }
    .causal-upload-gsignin {
        display: inline-flex;
        justify-content: flex-end;
        width: 357px;
        & > div {
            display: inline-block;
        }
        .causal-upload-button {
            height: 43px;
            width: 100px;
            margin-right: 25px;
        }
    }
    .causal-csv-reader {
        display: inline;
    }
    .causal-dropdown-button {
        min-width: 250px;
        justify-content: space-between;
    }
    .causal-dropdown-popover {
        max-height: 300px;
        overflow-y: auto;
    }
    .causal-date-pickers {
        display: flex;
    }
}

tr {
    &:hover td {
        background-color: initial !important;
    }
}

.table-hover {
    &:hover {
        background-color: rgba(191, 204, 214, 0.1);
    }
}