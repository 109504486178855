#causal-impact-container {
    padding: 20px;
    #causal-impact-header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 20px;
    }
    #causal-impact-type-selection {
        display: flex;
        justify-content: space-between;
    }
    #reports-list-table {
        width: 100%;
    }
    #causal-impact-errors {
        padding: 0 15px;
        list-style-type: decimal;
    }
    .causal-impact-report-view-row {
        display: flex;
        @media (max-width: 950px) {
            flex-direction: column;
        }
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        .ci-report-view-row-child {
            flex: 1;
            &:not(:last-child) {
                @media (max-width: 950px) {
                    margin-bottom: 20px;
                    margin-right: initial;
                }
                margin-right: 20px;
                margin-bottom: initial;
            }

            .report-view-summary {
                width: 100%;
                min-height: 400px;
            }

            .ci-report-view-request-data-table {
                width: 100%;

                .metadata-title {
                    font-weight: 600;
                }
            }
        }
    }

    .causal-impact-select {
        width: 400px;
    }
    .filter-container {
        display: flex;
        * {
            margin-right: 10px;
        }
    }
}