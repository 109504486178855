#bixby-wrapper {
    display: flex;
    min-height: 100vh;
    @import 'sidenav';

    #bixby-dashboard {
        display: flex;
        flex-direction: column;
        flex: 1;
        @import 'navbar';
        @import 'content';
        @import 'clients';
    }
}