$sidenav-width: 60px;
$sidenav-background-color: #253746;
$sidenav-button-color: #929BA3;

#bixby-sidenav {
    display: flex;
    flex-direction: column;
    width: $sidenav-width;
    background-color: $sidenav-background-color;
    padding: 16px 14px;
    box-sizing: border-box;
    align-items: center;
    #wpromote-logo {
        margin-bottom: 40px;
        max-width: 32px;
        img {
            max-width: inherit;
            max-height: auto;
        }
    }
    .sidenav-button {
        margin-bottom: 24px;
        :last-of-type {
            margin-bottom: 0;
        }
        span {
            svg {
                color: $sidenav-button-color;
            }
        }
    }
}