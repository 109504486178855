#client-area {
    display: flex;
    flex: 1;
    #client-side-bar {
        display: flex;
        flex-direction: column;
        width: 270px;
        padding: 30px 20px;
        box-sizing: border-box;
        align-items: center;
        .section-title {
            display: block;
            text-transform: uppercase;
            margin-bottom: 16px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.07;
            letter-spacing: 0.75px;
            color: #5C7080;
        }
        .client-logo {
            width: 100px;
            height: 100px;
            border: solid 1px rgba(146, 155, 163, 0.5);
            margin-bottom: 8px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .client-name {
            font-size: 18px;
            font-weight: 600;
            margin: 8px 0;
            color: #182026;
        }
        .client-cid {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            color: #5C7080;
        }
        .client-since {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            color: #5C7080;
            margin: 8px 0;
        }
        .client-scores {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin: 32px 0;
            text-align: center;
            .score {
                display: flex;
                flex-direction: column;
                color: #5C7080;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.36;
                letter-spacing: 0.88px;
            }
            .green-score {
                color: #3BB74F;
                font-size: 18px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.17;
                letter-spacing: 1px;
            }
        }
        .client-wpromote-team {
            align-self: flex-start;
            margin-bottom: 32px;
            .wpromote-team-data {
                padding: 0px 6px;
                box-sizing: border-box;
                font-size: 12px;
                .team-role {
                    font-weight: 500;
                }
            }
        }
        .client-contact {
            align-self: flex-start;
            margin-bottom: 32px;
            .client-contact-data {
                padding: 0px 6px;
                box-sizing: border-box;
                .client-contact-name {
                    display: block;
                    font-size: 12px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #182026;
                    margin-bottom: 8px;
                }
                .client-contact-title {
                    display: block;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #182026;
                    margin-bottom: 8px;
                }
                .client-contact-email {
                    display: block;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #58A4B0;
                    margin-bottom: 8px;
                }
                .client-contact-phonenum {
                    display: block;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #182026;
                }
            }
        }
        .client-tags {
            align-self: flex-start;
            display: flex;
            flex-direction: column;
            .tag-data {
                display: flex;
                flex-wrap: wrap;
                .bp3-tag {
                    flex: 1 0 23%;
                    margin-right: 8px;
                    margin-bottom: 13px;
                }
            }
        }
    }
    #client-content {
        //display: flex;
        flex: 1;
        .client-content-tabs {
            .bp3-tab-list {
                height: 80px;
                border-bottom: 3px solid rgba(16, 22, 26, 0.15);
                padding: 0px 32px;
                box-sizing: border-box;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                color: #182026;
            }
            .bp3-tab-panel {
                padding: 0px 32px;
                box-sizing: border-box;
            }
        }
    }
    .client-tabbed-content {
        display: flex;
        flex-direction: column;
        margin-top: 4px;
        flex: 1;
        .manager-icon {
            width: 30px;
            height: 30px;
            background-color: #d8d8d8;
            img {
                height: 100%;
                width: 100%;
            }
        }
        .client-external-account-form {
            * {
                border: none;
            }
        }
        .client-scope-header {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin-bottom: 24px;
        }
        .client-scope-table {
            width: 100%;
            .score-number {
                color: #FFFFFF;
                font-size: 13px;
            }
            .green-score {
                width: 20px;
                height: 20px;
                border-radius: 10px;
                background-color: #0f9960;
                display: flex;
                justify-content: center;
            }
            .orange-score {
                width: 20px;
                height: 20px;
                border-radius: 10px;
                background-color: #d9822b;
                display: flex;
                justify-content: center;
            }
            .red-score {
                width: 20px;
                height: 20px;
                border-radius: 10px;
                background-color: #db3737;
                display: flex;
                justify-content: center;
            }
            .status-active {
                background-color: rgba(15, 153, 96, 0.2);
                color: #0d8050;
            }
            .contract-inactive > td {
                color: rgba(92, 112, 128, 0.5);
            }
            .bp3-switch input {
                &:checked {
                    ~ .bp3-control-indicator {
                        background: #43bf4d;
                    }
                }
            }
        }
    }
}